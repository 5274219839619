import React from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import { ProfileCard } from "../components/cards";
import AboutUsJson from "../data/aboutUs.json";
import { data } from "../data/profileInfo";

export default function AboutUsContent() {
  const [state] = React.useState({
    abousUsData: AboutUsJson,
    profileInfoData: data,
  });

  return (
    <div className="aboutUsPageContainer">
      <Grid container xs={12} spacing="3">
        {state.abousUsData.map((item) => {
          return (
            <Grid item xs={12} sm={12} md={6} className="aboutUsInfoSection">
              <Paper elevation={0} style={{backgroundColor: "#F8F8F8"}}>
                <Typography className="title">{item.aboutTitle}</Typography>
                <div className="spacer" />
                <Typography className="description">
                  {item.aboutDescription}
                </Typography>
              </Paper>
            </Grid>
          );
        })}

        <Grid container sm={12}>
          <Grid item sm={12}>
            <div className="spacer" />
            <Typography className="pageSubTitleSection">
              Meet our Team
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3} className="profileContainer">
          {state.profileInfoData.map((item) => (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={5}
              className="profileInfo"
              style={{
                background: "#f8f8f8",
                borderRadius: "20px",
                padding: "15px",
                border: "10px solid #f8f8f8",
                textAlign: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
              }}
            >
              <ProfileCard
                name={item.name + " profile image"}
                source={item.image}
              />
              <Typography className="name">{item.name}</Typography>
              <Typography className="title">{item.title}</Typography>
              <div className="spacer" />
              <Typography variant="body1" className="desc">
                {item.about}
              </Typography>
              <div className="spacer" />
              <Typography className="email">{item.email}</Typography>
              <div className="spacer" />
            </Grid>
          ))}
        </Grid>

        <div className="spacer" />
        <div className="spacer" />
      </Grid>
    </div>
  );
}
