import DataIku from "../assets/images/partners/dataiku.jpg";
import DataBricks from "../assets/images/partners/databricks.jpg";
import AWS from "../assets/images/partners/AWS.png";


export const data = [
  {
    title: "Dataiku",
    desc: "Dataiku is the core technology for Tenjin Core, offering a scalable platform for developing and managing AI and machine learning models. It supports no-code, low-code, and full-code development, allowing users with different technical skills to participate in AI projects. This adaptability is vital for federal agencies needing rapid and collaborative AI model development. By using Dataiku, Tenjin Core helps organizations speed up data-driven decisions and implement AI across various missions.",
    subDesc: "Why It Matters: Integrating Dataiku into Tenjin Core provides a powerful, user-friendly platform for the entire AI lifecycle, from data preparation to model deployment. This enhances federal agencies' ability to use AI effectively, fostering innovation and efficiency in their operations.",
    image: DataIku,
    category: "Strategic",
  },
  {
    title: "Databricks",
    desc: "Databricks is a crucial partner for Tenjin, offering a unified data analytics platform widely used in the federal government. Integrating Tenjin with Databricks ensures seamless interoperability, enabling efficient use of Databricks' data engineering, machine learning, and analytics within Tenjin. This enhances data processing workflows and analysis of large datasets.",
    subDesc: "Why It Matters: Databricks’ widespread use and robust platform make it vital for Tenjin. This integration allows federal agencies to enhance their data analytics capabilities while leveraging existing investments and workflows, improving AI-driven insights and decision-making within the Tenjin ecosystem.",
    image: DataBricks,
    category: "Strategic",
  },
  {
    title: "AWS (Amazon Web Services)",
    desc: "AWS is a strategic partner of SAIC, providing cloud infrastructure for integrated solutions like Intelligent Document Management. This partnership aims to boost AWS usage in the federal market by offering scalable, secure, and efficient cloud solutions. AWS ensures Tenjin’s AI solutions are highly available, reliable, and capable of large-scale data processing.",
    subDesc: "Why It Matters: AWS’s collaboration with SAIC allows Tenjin to leverage advanced cloud technologies, ensuring scalable and secure solutions for federal agencies. This enhances operational efficiency and data management, driving AWS adoption within the federal sector.",
    image: AWS,
    category: "Strategic",
  },
];
