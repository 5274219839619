import {
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import DemoDialog from "./DemoDialog";

function DemoCard(props) {
  return (
    <Card
      sx={{ minHeight: 350, maxHeight: 450, overflow: "auto", background: "#F8F8F8" }}
      elevation={2}
    >
      <CardMedia
        sx={{ height: 150 }}
        image={`${process.env.PUBLIC_URL}${props.demoJson.overviewImage}`}
        title={props.demoJson.title}
      />

      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          sx={{ color: "#0E284F", textAlign: "center" }}
        >
          {props.demoJson.title}
        </Typography>

        <Typography
          variant="body1"
          color="#2A557F"
          sx={{ textAlign: "center" }}
        >
          {props.demoJson.subtitle}
        </Typography>
      </CardContent>

      <CardActions gutterBottom>
        <DemoDialog
          title={props.demoJson.title}
          subtitle={props.demoJson.subtitle}
          desc={props.demoJson.description}
          image={`${process.env.PUBLIC_URL}${props.demoJson.overviewImage}`}
          benefits={props.demoJson.benefits}
        />
      </CardActions>
    </Card>
  );
}

export default DemoCard;
