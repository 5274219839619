import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core/";
import { styled } from "@material-ui/core/styles";
import { CLOSE_ICON } from "../../../assets/icons/IconList";

//styled button with hover effect
const StyledButton = styled(Button)({
  background: "#28A745",
  height: "45px",
  fontSize: "55px",
  "&:hover": {
    background: "#28A745",
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DemoDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <StyledButton
        variant="contained"
        gutterBottom
        onClick={handleClickOpen}
      >
        LEARN MORE
      </StyledButton>

      <BootstrapDialog
        onClose={handleClose}
        maxWidth={"lg"}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <img
            src={props.image}
            style={{
              width: "100%",
              height: "100px",
              objectFit: "cover",
              borderRadius: "5px",
            }}
          />
          <Typography
            variant="h4"
            style={{ textAlign: "center", color: "#2A557F" }}
          >
            {props.title}
          </Typography>
        </DialogTitle>

        <DialogContent
          style={{
            height: "100%",
            paddingTop: "15px",
            marginLeft: "50px",
            marginRight: "50px",
          }}
          dividers
        >
          <Typography gutterBottom>{props.desc}</Typography>

          <div className="spacer" />

          <Typography gutterBottom>
            {props.benefits.map((item) => {
              return (
                <span key={item.title}>
                  <Typography variant="h5" style={{color: "#4A7FB2" }}>{item.title}</Typography>

                  <Typography variant="body1" style={{color: "#162227" }}>{item.description}</Typography>
                  <div className="spacer" />
                </span>
              );
            })}
          </Typography>
        </DialogContent>
        
        <DialogActions onClick={handleClose} style={{ cursor: "pointer" }}>
          <CLOSE_ICON /> &nbsp; CLOSE
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
