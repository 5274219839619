import * as React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function DescriptionSection(props) {
  const { demo } = props;

  return (
    <Paper
      square
      sx={{
        position: "relative",
        height: "auto",
        backgroundColor: "white",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        color: "#000",
        mb: 0,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,0)",
        }}
      />
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={7}>
          <Box
            sx={{
              textAlign: "center",
              p: { xs: 3, md: 6 },
              pr: { md: 0 },
            }}
          >
            <Typography sx={{ mt: 2 }} variant="h4" paragraph>
              {"Capability Summary"}
            </Typography>
            <Typography
              sx={{ whiteSpace: "pre-wrap" }}
              variant="body1"
              color="inherit"
              paragraph
            >
              {demo.map((item, index) => (
                <span key={index}>{item.description}</span>
              ))}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={7}>
          <Box
            sx={{
              textAlign: "center",
              p: { xs: 3, md: 6 },
              pr: { md: 0 },
            }}
          >
            <Typography sx={{ mt: 2 }} variant="h4" paragraph>
              {"Relevant Customers"}
            </Typography>
            <Typography
              sx={{ whiteSpace: "pre-wrap" }}
              variant="body1"
              color="inherit"
              paragraph
            >
              {demo.map((item, index) => (
                <span key={index}>{item.customerdata}</span>
              ))}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default DescriptionSection;
