import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { PartnersCard } from "../components/cards";
import { data } from "../data/partners.js";

export default function InvestmentsContent() {
  const [state] = React.useState({
    dataValues: data,
  });

  return (
    <div className="partnersContainer">
      <Grid
        container
        spacing={6}
        className="marginCenter"
        justifyContent="center"
        // alignItems="center"
      >
        {state.dataValues.map((data) => {
          if (data.category === "Strategic") {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={data.id}>
                <PartnersCard data={data} />
              </Grid>
            );
          }
          return null;
        })}
      </Grid>
    </div>
  );
}
