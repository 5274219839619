import React, { useEffect, componentDi } from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { MultiActionAreaCard } from "../components/cards";
import {
  EMAIL_ICON,
  BUILD_ICON,
  LIGHTBULB_ICON,
} from "../assets/icons/IconList";
import $ from "jquery";

export default function ContactUsContent(props) {
  useEffect(() => {
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src =
      "https://saicagile.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-onpk8x/b/7/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=632f7322";
    script.async = true;

    document.head.append(script);
  }, []);

  const handleExecution = () => {
    async function execution() {
      const response = await $.ajax({
        url: "https://saicagile.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-onpk8x/b/7/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=632f7322",
        type: "get",
        cache: true,
        dataType: "script",
      });

      window.ATL_JQ_PAGE_PROPS = {
        "632f7322": {
          triggerFunction: function (showCollectorDialog) {
            showCollectorDialog();
          },
        },
      };
    }
    execution();
  };

  const handleOpportunity = () => {
    $.ajax({
      url: "https://saicagile.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-onpk8x/b/7/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=3e91f960",
      type: "get",
      cache: true,
      dataType: "script",
    });

    window.ATL_JQ_PAGE_PROPS = {
      "3e91f960": {
        triggerFunction: function (showCollectorDialog) {
          showCollectorDialog();
        },
      },
    };
  };

  const handleEmail = async () => {
    console.log("Info Buton CLicked");

    $.ajax({
      url: "https://saicagile.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-onpk8x/b/7/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=81d88a32",
      type: "get",
      cache: true,
      dataType: "script",
    });

    window.ATL_JQ_PAGE_PROPS = {
      "81d88a32": {
        triggerFunction: function (showCollectorDialog) {
          showCollectorDialog();
        },
      },
    };
  };

  const handleTest = () => {
    console.log("Test");
  };

  return (
    <div className="contactUsContentContainer">
      <Grid
        xs={11}
        sm={8}
        md={11}
        lg={10}
        container
        spacing="3"
        className="marginCenter"
      >
        <Grid item xs={12}>
          <Typography variant="h4">
            Chad Innovation Factory Support Request
          </Typography>
        </Grid>

        <Grid xs={12} sm={12} md={4} lg={4} item>
          <MultiActionAreaCard
            image={props.content[0].image}
            title={props.content[0].title}
            desc={props.content[0].description}
          />
          <div className="spacer" />
          <div className="dividerShape" />
          <div className="spacer" />
          <Button
            class="btn"
            data-target="#email"
            id="email"
            onClick={() => {
              handleEmail();
            }}
          >
            <Typography>
              {" "}
              <EMAIL_ICON iconColor={"#fff"} iconSize={"20px"} />
              <span
                style={{
                  verticalAlign: "middle",
                  top: "-3px",
                  paddingLeft: "7px",
                  fontSize: "13px",
                }}
              >
                REQUEST INFO
              </span>
            </Typography>
          </Button>
        </Grid>

        <Grid xs={12} sm={12} md={4} lg={4} item>
          <MultiActionAreaCard
            image={props.content[1].image}
            title={props.content[1].title}
            desc={props.content[1].description}
          />
          <div className="spacer" />
          <div className="dividerShape" />
          <div className="spacer" />
          <Button
            class="btn"
            data-target="#opportunity"
            id="opportunity"
            onClick={() => {
              handleOpportunity();
            }}
          >
            <Typography>
              {" "}
              <LIGHTBULB_ICON iconColor={"#f3ffa4"} iconSize={"20px"} />
              <span
                style={{
                  verticalAlign: "middle",
                  top: "-2px",
                  paddingLeft: "7px",
                  fontSize: "13px",
                }}
              >
                REQUEST OPP SUPPORT
              </span>
            </Typography>
          </Button>
        </Grid>

        <Grid xs={12} sm={12} md={4} lg={4} item>
          <MultiActionAreaCard
            image={props.content[2].image}
            title={props.content[2].title}
            desc={props.content[2].description}
          />
          <div className="spacer" />
          <div className="dividerShape" />
          <div className="spacer" />
          <Button
            class="btn"
            data-target="#execution"
            id="execution"
            onClick={() => {
              handleExecution();
            }}
          >
            <Typography>
              {" "}
              <BUILD_ICON iconColor={"#fff"} iconSize={"20px"} />
              <span
                style={{
                  verticalAlign: "middle",
                  top: "-3px",
                  paddingLeft: "7px",
                  fontSize: "13px",
                }}
              >
                REQUEST EXE SUPPORT
              </span>
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
