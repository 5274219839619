import TenjinLogo from "../assets/images/Tenjin_outline_dark.png";

export const futureData = [
    {
        sectionTitle: "Future Enhancements and New Developments"
      },
      {
        title: "Tenjin Core V2",
        desc: "The upcoming Version 2 of Tenjin Core will introduce new features, including GenAI integrations, quick-start projects, mission-focused projects, easier deployment and management, user training, and an enterprise portal. This release aims to enhance the platform’s capabilities and user experience further. Expected October FY25.",
        image: TenjinLogo,
        link: "https://marketplace.msp.saic.com/",
        color: "#2A557F",
      },
      {
        title: "Generative AI Approach",
        desc: "The Tenjin portfolio will incorporate a Generative AI approach to enhance document generation, policy adherence, and query handling. This framework will leverage Retrieval-Augmented Generation (RAG) systems and an LLM agnostic approach to automate document-related tasks and provide accurate, context-aware answers to complex queries. This solution will support secure and efficient management of extensive paperwork and policy documentation problems our customers have. Expected January FY25.",
        image: TenjinLogo,
        link: "https://marketplace.msp.saic.com/",
        color: "#2A557F",
      },
      {
        title: "Machine Learning Operations Framework",
        desc: "A new MLOps framework is being written and built to automate the deployment, management, and monitoring of machine learning models. This framework will ensure model accuracy and performance over time, facilitating continuous improvement and compliance with federal standards for security and reliability. This framework will be available and accessible with architecture diagrams and written content for use in proposals or to implement onto our SAIC programs. Expected January FY25.",
        image: TenjinLogo,
        link: "https://marketplace.msp.saic.com/",
        color: "#2A557F",
      },
];