import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import PropTypes from "prop-types";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { AppBar, Toolbar, Button, Grid } from "@material-ui/core";
import SaicLogo from "../assets/images/Saic-Logo.png";
import NavBar from "./navBar";

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

export default function ButtonAppBar(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <div className="headerBar">
        <ElevationScroll {...props}>
          <AppBar>
            <Toolbar>
              <Grid container xs={12}>
                <Grid item xs={3} sm={4} md={3} lg={2}>
                  <a href={"/"}>
                    <img
                      src={SaicLogo}
                      alt="SAIC LOGO"
                      style={{ marginTop: "8px" }}
                    />
                  </a>
                </Grid>
                <Grid item xs={6} sm={5} md={6} lg={8}>
                  <NavBar />
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={2}>
                  <Button
                    href="https://digital.saicif.com/"
                    className="button"
                    target="_blank"
                    style={{ marginTop: "15px", backgroundColor: "#28A745", color: '#f8f8f8', fontWeight: '700' }}
                  >
                    Contact Us
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
      </div>
    </React.Fragment>
  );
}
