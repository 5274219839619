import * as React from "react";
import OverviewHeader from "./OverviewHeader";
import DescriptionSection from "./DescriptionSection";
import ImageSection from "./ImageSection";
import BenefitsSection from "./BenefitsSection";
import Header from "../Header/Header";
import { useLocation } from "react-router-dom";

import demosJson from "../demo";

function DemoPage() {
  // const location = useLocation()

  // let path = location.pathname.substr(1)
  // const filteredDemo = demosJson.filter(demo => demo.title.replace(/\s/g, '') === path)
  // const demo = filteredDemo[0]

  return (
    <div>
      {/* <Header /> */}
      {/* <OverviewHeader demo={demo} /> */}
      <DescriptionSection demo={demosJson} />
      <ImageSection demo={demosJson} />
      {/*<BenefitsSection demo={demo} /> */}
    </div>
  );
}

export default DemoPage;
