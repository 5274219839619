import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { TrimWhiteSpace } from "../functions/utils";
import VideoData from "../data/videoCarousel.json";
import { styled } from "@material-ui/core/styles";

export default function VideoDemoCarousel() {
  const [state] = React.useState({
    dataValues: VideoData,
  });

  const StyledButton = styled(Button)({
    background: "#28A745",
    width: '175px',
    height: "55px",
    fontSize: "55px",
    fontWeight: '600',
    "&:hover": {
      background: "#28A745",
    },
  });

  return (
    <div className="videoDemoCarouselContainer">
      {state.dataValues.map((item) => {
        return (
          <Grid container xs={12}>
            <Grid item xs={12}>
              <Typography className="sectionTitle">
                Tenjin Core Introduction
              </Typography>
            </Grid>
            <div className="spacer" />

            <Grid item xs={12} sm={12} md={12} lg={8}>
              <video controls>
                <source src={item.src} />
              </video>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              className="sectionDescription"
            >
              <Typography component="h4" variant="h4" className="title">
                {item.title}
              </Typography>
              <div className="spacer" />
              <Typography className="text">{item.description}</Typography>

              <div className="spacer" />
              <div className="spacer" />

              <StyledButton href="/offerings" variant="contained" gutterBottom >
                LEARN MORE
              </StyledButton>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
}
