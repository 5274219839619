import Max from "../assets/images/profile/Max.jpeg";
import Justin from "../assets/images/profile/Justin.jpeg";
import Ali from "../assets/images/profile/ali.jpeg";
import Sana from "../assets/images/profile/Sana.jpeg";

export const data = [
  {
    name: "Max Thompson",
    title: "AI Portfolio Director",
    about: "2024 CINO MVP Award Winner",
    email: "max.w.thompson@saic.com",
    image: Max,
  },
  {
    name: "Allison Banzon",
    title: "AI Ethicist and Learning Analyst",
    // about: "2024 CINO MVP Award Winner",
    email: "allison.m.banzon@saic.com",
    image: Ali,
  },
  {
    name: "Sana Ahmed",
    title: "Product Owner: Tenjin Core",
    // about: "2024 CINO MVP Award Winner",
    email: "Sana.r.ahmed@saic.com",
    image: Sana,
  },
  {
    name: "Justin Jackson",
    title: "Product Owner: Mission Accelerators/Tenjin Edge",
    // about: "             ",
    email: "justin.m.jackson@saic.com",
    image: Justin,
  },
];
