import React from "react";
import { Typography } from "@material-ui/core";
import AccordionComponent from "../components/accordion";

export default function faqContent(props) {
  return (
    <div className="faqContentContainer">
      {/* Features and Capabilities SECTION */}
      <Typography className="category">Features and Capabilities</Typography>
      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "Features and Capabilities") {
            return (
              <AccordionComponent
                question={data.question}
                answer={data.answer}
                answer2={data.answer2}
                details={data.details}
                alternativeDetails={data.alternativeDetails}
                conclusion={data.conclusion}
              />
            );
          }
        })}
      </div>

      {/* Integration and Compatibility SECTION */}
      <Typography className="category">
        Integration and Compatibility
      </Typography>

      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "Integration and Compatibility") {
            return (
              <AccordionComponent
                question={data.question}
                answer={data.answer}
                answer2={data.answer2}
                answer3={data.answer3}
                details={data.details}
                alternativeDetails={data.alternativeDetails}
                list={data.list}
                list2={data.list2}
                conclusion={data.conclusion}
              />
            );
          }
        })}
      </div>

      {/* Security and Compliance SECTION */}
      <Typography className="category">Security and Compliance</Typography>

      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "Security and Compliance") {
            return (
              <AccordionComponent
                category={data.category}
                question={data.question}
                answer={data.answer}
                answer2={data.answer2}
                answer3={data.answer3}
              />
            );
          }
        })}
      </div>

      {/* Delivery, Support and Services SECTION */}
      <Typography className="category">
        Delivery, Support and Services
      </Typography>

      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "Delivery Support and Services") {
            return (
              <AccordionComponent
                category={data.category}
                question={data.question}
                answer={data.answer}
                answer2={data.answer2}
                answer3={data.answer3}
                details={data.details}
                conclusion={data.conclusion}
              />
            );
          }
        })}
      </div>

      {/* Pricing, Access and Demos SECTION */}
      <Typography className="category">Pricing, Access and Demos</Typography>

      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "Pricing, Access, and Demos") {
            return (
              <AccordionComponent
                category={data.category}
                question={data.question}
                answer={data.answer}
                answer2={data.answer2}
                details={data.details}
                list={data.list}
                conclusion={data.conclusion}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
