import { StylesProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import AppTemplate from "./template/appTemplate"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Home from "./Home"
import FAQPage from "./faqs"
import DemosPage from "./demos"
import OfferingsPage from "./offerings"
import AboutUsPage from "./aboutUs"
import PartnersPage from "./partners"
import ContactUsPage from './contactUs'
import KoverseCatalogPage from "./koverseCatalog"
import DemoPage from "./content/KoverseCatalog/Demo Page/DemoPage"
import DemoListPage from "./content/KoverseCatalog/Demo List/DemoListPage"
import { DemoProvider } from './content/KoverseCatalog/DemoContext';

import "./assets/sheets/main.scss"

function App() {
  return (
    <StylesProvider injectFirst>
       <DemoProvider>
      <CssBaseline />
      <AppTemplate>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/faqs" element={<FAQPage />} />    
            <Route path="/solutions" element={<DemoListPage />} />
            <Route path="/:demoName" element={<DemoPage />} />

            <Route path="/offerings" element={<OfferingsPage />} />
            <Route path="/aboutUs" element={<AboutUsPage />} />
            <Route path="/partners" element={<PartnersPage />} />
            <Route path='/contactUs' element={<ContactUsPage />} />
          </Routes>
        </BrowserRouter>
      </AppTemplate>
      </DemoProvider>
    </StylesProvider>
  )
}

export default App
