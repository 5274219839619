import React from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core/";
import { TrimWhiteSpace } from "../functions/utils";
import { MENU_ICON, MENU_UP_ARROW } from "../assets/icons/IconList";
import SAIC_LOGO from "../assets/images/Saic-Logo.png";

export default function DrawerComponent() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="mobileNav"
    >
      <div className="spacer" />
      <img src={SAIC_LOGO} alt="Saic Logo" className="logo" />
      <div className="dividerShape2" />

      <List className="mobileNavLinks">
        <a href="/">
          <ListItem button>
            <ListItemText>Home</ListItemText>
          </ListItem>
        </a>

        <a
          href="https://issaic.saic.com/sites/innovationfactory/overview"
          target="_blank"
          rel="noreferrer"
        >
          <ListItem button>
            <ListItemText>ISSAIC</ListItemText>
          </ListItem>
        </a>

        <a href="/offerings">
          <ListItem button>
            <ListItemText>Offerings</ListItemText>
          </ListItem>
        </a>

        <a href="/solutions">
          <ListItem button>
            <ListItemText>Solutions</ListItemText>
          </ListItem>
        </a>

        <a href="/partners">
          <ListItem button>
            <ListItemText>Partners</ListItemText>
          </ListItem>
        </a>

        <a href="/faqs">
          <ListItem button>
            <ListItemText>FAQs</ListItemText>
          </ListItem>
        </a>

        <a href="/aboutUs">
          <ListItem button>
            <ListItemText>About Us</ListItemText>
          </ListItem>
        </a>
      </List>
      <div className="spacer" />
      <div className="mobileNavCloseMenu">
        <IconButton>
          <MENU_UP_ARROW iconSize={"40px"} iconColor={"#F8F8F8"} />
        </IconButton>
      </div>
    </div>
  );

  return (
    <div className="menuHamIcon">
      {["top"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton color="inherit" onClick={toggleDrawer(anchor, true)}>
            <MENU_ICON />
          </IconButton>

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
