import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";

export default function AccordionComponent(props) {
  const [expanded, setExpanded] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // ACCORDION PROPS
  const {
    question,
    answer,
    answer2,
    answer3,
    alternativeDetails,
    details,
    list,
    list2,
    conclusion,
  } = props;

  // JSON HTML MARKUP
  const createMarkup = (html, links = []) => {
    if (!links.length) return { __html: html };

    let result = html;

    links.forEach(({ text, url }) => {
      const linkMarkup = `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: #28A745; text-decoration: underline;">${text}</a>`;
      result = result.replace(new RegExp(`(${text})`, "g"), linkMarkup);
    });

    return { __html: result };
  };

  const links = [
    { text: "IFSR", url: "https://digital.saicif.com" },
    {
      text: "Commercial Marketplace",
      url: "https://sb-commercial-marketplace-prod.auth.us-east-1.amazoncognito.com/login?redirect_uri=https%3A%2F%2Fmarketplace.msp.saic.com%2Fredirect-page&response_type=code&client_id=493brgn5biif6cojqgm429g1lc&identity_provider=COGNITO&scope=openid%20aws.cognito.signin.user.admin&state=9EO5IlILAVai3irbCBjzur2aTfG5S6RG&code_challenge=GFJheR7bfqDPekR2MBlknZo8k0Qe4xOUUG1EfgHaKQg&code_challenge_method=S256",
    },
  ];

  // JSON IMAGE HANDLING
  const handleImageClick = (imageURL) => {
    setFullscreenImage(imageURL);
  };

  const handleCloseFullscreen = () => {
    setFullscreenImage(null);
  };

  const renderDetails = (detailsList) =>
    detailsList.map((detail, index) => (
      <div key={index}>
        <AccordionDetails>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            {detail.question}
          </Typography>
        </AccordionDetails>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body2">
                {detail.answer && (
                  <div dangerouslySetInnerHTML={createMarkup(detail.answer)} />
                )}
              </Typography>
            </Grid>
            {detail.imageURL && (
              <Grid item xs={12} md={6}>
                <img
                  src={process.env.PUBLIC_URL + detail.imageURL}
                  alt="Detail"
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    cursor: "pointer",
                    marginTop: "-500px",
                  }}
                  onClick={() => handleImageClick(detail.imageURL)}
                />
              </Grid>
            )}
            {fullscreenImage && (
              <Dialog
                open={true}
                onClose={handleCloseFullscreen}
                maxWidth="xl"
                fullWidth
              >
                <DialogContent>
                  <IconButton
                    style={{ position: "absolute", right: 0, top: 0 }}
                    onClick={handleCloseFullscreen}
                  >
                    <CloseIcon />
                  </IconButton>
                  <img
                    src={process.env.PUBLIC_URL + fullscreenImage}
                    alt="Fullscreen"
                    style={{ width: "auto", height: "auto", padding: "50px" }}
                  />
                </DialogContent>
              </Dialog>
            )}
            <Grid item xs={12} md={6}>
              <Typography variant="body2">
                {detail.subAnswer && (
                  <div
                    dangerouslySetInnerHTML={createMarkup(detail.subAnswer)}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2">
                {detail.subAnswer2 && (
                  <div
                    dangerouslySetInnerHTML={createMarkup(detail.subAnswer2)}
                  />
                )}
              </Typography>
            </Grid>
          </Grid>
        </AccordionDetails>
      </div>
    ));

  const renderList = (items) =>
    items.map((item, index) => (
      <Grid container key={index} spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ color: "#0E284F", fontWeight: "bold" }}
          >
            {item.question}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" style={{ color: "#4A7FB2" }}>
            {item.answer && (
              <div dangerouslySetInnerHTML={{ __html: item.answer }} />
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            {item.subAnswer && (
              <div dangerouslySetInnerHTML={{ __html: item.subAnswer }} />
            )}
            {item.subAnswer2 && (
              <div dangerouslySetInnerHTML={{ __html: item.subAnswer2 }} />
            )}
          </Typography>
        </Grid>
        {item.subList && Array.isArray(item.subList) && (
          <Grid item xs={12} style={{ paddingLeft: "20px" }}>
            {item.subList.map((subItem, subIndex) => (
              <div key={subIndex}>
                {Object.keys(subItem).map((key) => (
                  <Typography key={key} variant="body2">
                    <div dangerouslySetInnerHTML={{ __html: subItem[key] }} />
                  </Typography>
                ))}
              </div>
            ))}
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body2">
            {item.subAnswer3 && (
              <div dangerouslySetInnerHTML={{ __html: item.subAnswer3 }} />
            )}
            {item.subAnswer4 && (
              <div dangerouslySetInnerHTML={{ __html: item.subAnswer4 }} />
            )}
          </Typography>
        </Grid>
      </Grid>
    ));

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h5">{question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            {answer && (
              <div dangerouslySetInnerHTML={createMarkup(answer, links)} />
            )}
          </Typography>
        </AccordionDetails>
        <AccordionDetails>
          <Typography variant="body1">
            {answer2 && (
              <div dangerouslySetInnerHTML={createMarkup(answer2, links)} />
            )}
          </Typography>
        </AccordionDetails>
        <AccordionDetails>
          <Typography variant="body1">
            {answer3 && (
              <div dangerouslySetInnerHTML={createMarkup(answer3, links)} />
            )}
          </Typography>
        </AccordionDetails>
        {details && renderDetails(details)}
        {alternativeDetails && alternativeDetails.length > 0 && (
          <AccordionDetails>
            <Grid container spacing={2}>
              {alternativeDetails.map((detail, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel2-content-${index}`}
                      id={`panel2-header-${index}`}
                    >
                      <Typography variant="h6">{detail.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body2">
                        <div
                          dangerouslySetInnerHTML={createMarkup(
                            detail.answer,
                            links
                          )}
                        />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        )}
        {list && list.length > 0 && (
          <AccordionDetails>{renderList(list)}</AccordionDetails>
        )}
        {list2 && list2.length > 0 && (
          <AccordionDetails>{renderList(list2)}</AccordionDetails>
        )}
        {conclusion && (
          <AccordionDetails>
            <Typography>
              <div dangerouslySetInnerHTML={createMarkup(conclusion, links)} />
            </Typography>
          </AccordionDetails>
        )}
      </Accordion>
      <br />
    </div>
  );
}
