import React from "react";
import { Typography, Grid, makeStyles } from "@material-ui/core";
import { RegCard } from "../components/cards";
import TenjinBanner from "../assets/images/Tenjin/Tenjin_banner_Final.jpg";
import AboutAIJson from "../data/aboutAI.json";

const useStyles = makeStyles((theme) => ({
  banner: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      height: "auto", // Adjust as needed
    },
  },
  spacer: {
    height: theme.spacing(2), // Adjust spacing as needed
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: theme.spacing(2),
    gap: theme.spacing(4), // Space between cards
  },
  cardItem: {
    flex: "1 1 auto", // Allow cards to grow and shrink as needed
    maxWidth: "325px", // Max width to control card size
    display: "flex",
    alignItems: "stretch", // Ensure all cards are of the same height
  },
}));

export default function AboutAI() {
  const classes = useStyles();
  const [state] = React.useState({
    dataValues: AboutAIJson,
  });

  return (
    <div className="aboutAIContainer">
      <Grid container>
        <Grid item xs={12}>
          <img
            src={TenjinBanner}
            alt="Tenjin Banner"
            className={classes.banner}
          />
        </Grid>
      </Grid>

      <div className={classes.spacer} />

      <div className={classes.cardContainer}>
        {state.dataValues.map((item) => (
          <div key={item.title} className={classes.cardItem}>
            <RegCard
              titleColor={item.color}
              title={item.title}
              desc={item.description}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
