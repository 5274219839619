import TenjinCore from "../assets/images/Tenjin/Tenjin_core.png";
import TenjinEdge from "../assets/images/Tenjin/Tenjin_edge.png";
import Tenjin from "../assets/images/Tenjin/Tenjin_title_blank.png";
import MXLogo from "../assets/images/Tenjin/MX_logo.png";
import AccelImg from "../assets/images/Accelerators.png";

export const data = [
  {
    // title: "Tenjin",
    desc: "SAIC’s AI team offer a comprehensive suite of AI-driven solutions designed to enhance data management, operational efficiency, and decision-making capabilities for federal and defense agencies. This robust portfolio addresses critical needs in integrated solutions, operational AI, and secure data analytics, ensuring that organizations can leverage cutting-edge technologies to meet their mission objectives effectively and securely.",
    image: Tenjin,
    link: "https://marketplace.msp.saic.com/",
    color: "#2C6CB0",
  },
  {
    // title: "Tenjin Core",
    desc: "Tenjin Core is the foundational platform within the Tenjin portfolio. It provides a collaborative workspace for both technical and non-technical users to develop, deploy, and manage AI and machine learning models. The platform supports no-code, low-code, and full-code development, enabling users to build and operationalize AI solutions rapidly. Tenjin Core facilitates data orchestration, model training, and deployment, and continued governance, making it an essential tool for accelerating data-driven decision-making.",
    image: TenjinCore,
  },
  {
    // title: "Tenjin Edge",
    desc: "Tenjin Edge is being developed to provide real-time AI capabilities at the edge of the battlefield. It will enable real-time data processing and analysis directly on small form-factor devices in Denied, Disrupted, Intermittent, and Limited (DDIL) environments. This product aims to enhance situational awareness and decision-making in dynamic operational settings.",
    image: TenjinEdge,
  },
  {
    // title: "Mission Accelerators",
    desc: "The Tenjin portfolio currently includes various specialized Mission Accelerators designed to address specific operational needs:",
    image: MXLogo,
    image2: AccelImg,
    link: "https://marketplace.msp.saic.com/",
    color: "#4BAAD0",
  },
  {
    title: "Outcome-Based Labor Packages",
    desc: "Tenjin’s outcome-based labor packages offer tailored services to ensure successful implementation and operationalization of AI solutions. These packages focus on delivering specific outcomes, such as model development, deployment, and optimization, based on the unique needs of each organization. This approach ensures that customers achieve measurable results from their AI investments.",
    image: Tenjin,
    link: "https://marketplace.msp.saic.com/",
    color: "#2A557F",
  },
];
