import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Link,
  CardMedia,
  CardActionArea,
} from "@material-ui/core/";
import { APSECT_RATIO_ICON } from "../assets/icons/IconList";
import { ListIconComponent } from "../components/list";

export default function MediaCard(props) {
  return (
    <div>
      <Link href={`/videos/${props.title}`} target="_blank">
        <Card
          style={{
            width: "100%",
            height: "475px",
            marginTop: "30px",
            textAlign: "center",
            background: "#f6f6f6",
            border: "10px solid #fff",
          }}
        >
          <CardContent style={{ marginTop: "-20px", color: "#006bb6" }}>
            {props.type === "Video" ? (
              <ListIconComponent
                icon={<APSECT_RATIO_ICON iconSize={"16px"} />}
                text={"[View Full Length Video ]"}
              />
            ) : (
              <br />
            )}

            {props.type === "Video" ? (
              <video controls style={{ margin: "0 auto", width: "100%" }}>
                {" "}
                <source src={props.source + "#t=0,30"} type="video/mp4" /> Video
                Not Playing
              </video>
            ) : (
              <div>
                <img
                  src={props.source}
                  alt={props.title}
                  style={{
                    textAlign: "center",
                    margin: "0 auto",
                    width: "150px",
                    paddingBottom: "20px",
                    paddingTop: "10px",
                    borderRadius: "10px",
                  }}
                />
              </div>
            )}
          </CardContent>
        </Card>
      </Link>
    </div>
  );
}

function ProfileCard(props) {
  return (
    <div className="ProfileCard">
      <img src={props.source} alt={props.name} />
    </div>
  );
}

function RegCard(props) {
  return (
    <div className="Card">
      <div className="cardTitleContainer" style={{ background: props.titleColor }}>
        <Typography component="h5" variant="h5" className="cardTitle">
          {props.title}
        </Typography>
      </div>

      <div className="cardBodyContainer">
        <Typography className="cardText">
          {props.desc}
        </Typography>
      </div>
    </div>
  );
}


function PartnersCard(props) {
  return (
    <div>
      <Card className="PartnersCard">
        <CardMedia
          component="img"
          image={props.data.image}
          alt={props.data.title}
        />
        <CardContent>
          <Typography variant="body1" color="#162227">
            {props.data.desc}
            <div className="spacer" />
            {props.data.subDesc}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

function MultiActionAreaCard(props) {
  return (
    <div>
      <Card
        sx={{ maxWidth: 345 }}
        style={{ height: "375px", paddingBottom: "25px" }}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image={props.image}
            alt={props.title}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              style={{ fontSize: "20px", padding: "10px", color: "#01518b" }}
            >
              {props.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {props.desc}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}

export { MediaCard, RegCard, ProfileCard, PartnersCard, MultiActionAreaCard };
