import React from "react";
import { Typography, Grid, Button } from "@material-ui/core";
import { DESC_ICON } from "../assets/icons/IconList";
import { futureData } from "../data/futureOfferings";
import { data } from "../data/offerings";
import { styled } from "@material-ui/core/styles";

export default function OfferingsContent(props) {
  const [state] = React.useState({
    dataValues: data,
    futureData: futureData,
  });

  const StyledButton = styled(Button)({
    background: "#28A745",
    height: "45px",
    fontSize: "55px",
    "&:hover": {
      background: "#28A745",
    },
  });

  return (
    <div className="offeringsContentContainer">
      <Grid
        container
        xs={12}
        sm={12}
        md={11}
        lg={10}
        spacing={3}
        className="container"
      >
        <StyledButton
          variant="contained"
          gutterBottom
          style={{
            fontSize: "18px",
            textAlign: "center",
            width: "325px",
            height: "50px",
            padding: "10px",
            color: "#f8f8f8",
          }}
          href="https://saiconline.sharepoint.us/teams/AI_HBU/SitePages/Solution-Reuse.aspx"
        >
          DOCUMENT REPOSITORY{" "}
        </StyledButton>

        <div className="spacer" />
        <div className="spacer" />

        {state.dataValues.map((data) => {
          return (
            <div>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <a href={data.link} target="_blank" rel="noreferrer">
                  <img
                    src={data.image}
                    alt="Offerings Partner Logo"
                    className="image"
                  />
                </a>
                <div className="spacer" />
                <Typography
                  paragraph
                  variant="h5"
                  style={{ color: data.color }}
                >
                  {data.title}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body1" className="desc" paragraph>
                  {data.desc}
                </Typography>
              </Grid>

              <div className="spacer" />

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <img className="accelImg" src={data.image2} />
              </Grid>

              <div className="spacer" />
            </div>
          );
        })}

        <div className="spacer" />
        <div className="spacer" />

        {state.futureData.map((futureData) => {
          return (
            <div>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="h4" paragraph>
                  {futureData.sectionTitle}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  variant="h5"
                  paragraph
                  style={{ color: futureData.color }}
                >
                  {futureData.title}
                </Typography>
              </Grid>

              <div className="spacer" />

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body1" paragraph>
                  {futureData.desc}
                </Typography>
              </Grid>
            </div>
          );
        })}
      </Grid>
    </div>
  );
}
