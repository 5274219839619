import React from "react"
import { Grid, Typography } from "@material-ui/core"
import AwsExportConfig from "./aws-exports"
import { Auth, Amplify } from "aws-amplify"
import FaqsContent from "./content/faq"
import faqsData from "./data/faq.json"
import newFAQ from "./data/newFAQ.json"

Amplify.configure(AwsExportConfig());

class FAQsPage extends React.Component {
  state = {
    dataValues: faqsData,
    newDataValues: newFAQ
  };

  componentDidMount() {
    const getUser = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        console.log("Pages: ", authenticatedUser);
      } catch {
        console.log("The user isn't signed in");

        setTimeout(() => {
          Auth.federatedSignIn({ customProvider: "SAIC" });
        }, 2000);
      }
    };

    getUser();
  }

  render() {
    return (
      <main>
        <Grid container xs={12} sm={12} className="fadePanel">
          <Grid item xs={12} sm={12}>
            <Typography variant="h5" className="pageTitleSection">
            FREQUENTLY ASKED QUESTIONS
            </Typography>
            <div className="spacer" />
          </Grid>

          <Grid item xs={12} sm={12}>
            <br />
            <FaqsContent content={this.state.newDataValues} />
          </Grid>
        </Grid>
      </main>
    );
  }
}

export default FAQsPage;
