import { Box, Typography } from "@mui/material";

function DemoListHeader() {
  return (
    <Box style={{ textAlign: "center" }} sx={{ flexGrow: 1 }}>
      <Typography variant="h3" style={{ color: "#0E284F" }}>
        Explore AI and Machine Learning Solutions
      </Typography>
      <Typography variant="h5" style={{ color: "#4A7FB2" }} paragraph>
        Learn more about Tenjin's capabilities and how they're used to meet
        mission needs
      </Typography>
      <div className="spacer"/>
    </Box>
  );
}

export default DemoListHeader;
