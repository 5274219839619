import React from "react"
import { Grid, Typography } from "@material-ui/core"
import AwsExportConfig from "./aws-exports"
import { Auth, Amplify } from "aws-amplify"
import KoverseCatalog from "./content/KoverseCatalog/Demo Page/DemoPage"

Amplify.configure(AwsExportConfig());

class KoverseCatalogPage extends React.Component {
  componentDidMount() {
    const getUser = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        console.log("Pages: ", authenticatedUser);
      } catch {
        console.log("The user isn't signed in");

        setTimeout(() => {
          Auth.federatedSignIn({ customProvider: "SAIC" });
        }, 2000);
      }
    };

    getUser();
  }

  render() {
    return (
      <main className="koverseCatalogContainer" >
        <Grid container xs={12} >
          <Grid item xs={12} >
           <KoverseCatalog />
          </Grid>
        </Grid>
      
      </main>
    )
  }
}

export default KoverseCatalogPage
