import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core"
import BadgeComponent from "./components/badge"
import AwsExportConfig from "./aws-exports"
import { Auth, Amplify } from "aws-amplify"
import { postVideoTrackerData, getVideoData } from "./service/services"

Amplify.configure(AwsExportConfig());

const BASE_URL = process.env.REACT_APP_BASE_URL;

function DemosPage(props) {
  const [state, setState] = React.useState({
    dataValues: [],
    videoSelected: "",
  });

  useEffect(() => {
    const getUser = async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        console.log("Pages: ", authenticatedUser);
      } catch {
        console.log("The user isn't signed in");

        setTimeout(() => {
          Auth.federatedSignIn({ customProvider: "SAIC" });
        }, 2000);
      }
    };

    getUser();

    retrieveData();
  }, []);

  // Service Calls
  const retrieveData = () => {
    getVideoData()
      .then((response) => {
        setState({
          dataValues: response.data.body,
        });
        console.log(state.dataValues);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const videoTracker = (videoClicked) => {
    console.log("Video Clicked: ", videoClicked);

    postVideoTrackerData(videoClicked);
  };

  const retrieveViewCount = (videoType) => {
    return (
      <div>
        {state.dataValues.map((data) => {
          if (trimWhiteSpace(videoType) === trimWhiteSpace(data.Title)) {
            return data.ViewCount;
          }
        })}
      </div>
    );
  };

  const retrieveVideoContent = (videoType) => {
    return (
      <div>
        {state.dataValues.map((data) => {
          if (trimWhiteSpace(videoType) === trimWhiteSpace(data.Title)) {
            videoTracker(videoType);

            return (
              <div style={{ margin: "0 auto" }}>
                <video
                  controls
                  style={{
                    width: "90%",
                    borderRadius: "10px",
                    padding: "20px",
                  }}
                >
                  {" "}
                  <source src={data.Source} type="video/mp4" /> Video Not
                  Playing
                </video>
              </div>
            );
          }
        })}
      </div>
    );
  };

  // Retrieve Category Type
  const retrieveCategoryType = (videoType) => {
    return (
      <div>
        {state.dataValues.map((data) => {
          if (trimWhiteSpace(videoType) === trimWhiteSpace(data.Title)) {
            return (
              <div style={{ paddingLeft: "25px" }}>
                <small>
                  <b>Group Category:</b> &nbsp;{data.Group}
                </small>
              </div>
            );
          }
        })}
      </div>
    );
  };

  // Retrieve Video Title
  const retrieveVideoTitle = (videoType) => {
    return (
      <div>
        {state.dataValues.map((data) => {
          if (trimWhiteSpace(videoType) === trimWhiteSpace(data.Title)) {
            return (
              <div style={{ paddingLeft: "25px" }}>
                <Typography>{data.Title}</Typography>
              </div>
            );
          }
        })}
      </div>
    );
  };

  const VideoContent = (videoType) => {
    return (
      <div>
        <Grid container xs={12} sm={12}>
          <Grid container xs={12} sm={9}>
            <Grid item xs={12} sm={12}>
              {retrieveVideoContent(videoType)}
              <Typography variant="h6">
                &nbsp; &nbsp; {retrieveVideoTitle(videoType)}{" "}
              </Typography>
              <Typography>{retrieveCategoryType(videoType)}</Typography>

              <Typography variant="body1">
                <BadgeComponent count={retrieveViewCount(videoType)} />
                <br />
                <br />
              </Typography>

              {ListContentComponent(videoType)}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={3} className="videoThumbnailContainer">
            {ListMediaComponent(videoType)}
          </Grid>
        </Grid>
      </div>
    );
  };

  const ListContentComponent = (videoType) => {
    const retrieveContent = () => {
      return state.dataValues.map((data) => {
        if (trimWhiteSpace(videoType) === trimWhiteSpace(data.Title)) {
          return (
            <div style={{ width: "95%" }}>
              <Typography
                variant="h5"
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                  margin: "0",
                  padding: "25px",
                }}
              >
                Summary
              </Typography>

              <Typography
                variant="body1"
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  margin: "0",
                  paddingLeft: "25px",
                }}
              >
                {data.Summary}
              </Typography>

              <div className="spacer" />
              <div className="spacer" />
            </div>
          );
        }
      });
    };

    return <div>{retrieveContent()}</div>;
  };

  const ListMediaComponent = () => {
    return (
      <div>
        {state.dataValues.map((data) => {
          return (
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <Link to={`/demos/` + trimWhiteSpace(data.Title)}>
                    <TableRow className="Link">
                      <TableCell component="th" scope="row">
                        <video style={{ width: "125px", height: "100px" }}>
                          {" "}
                          <source src={data.Source} type="video/mp4" /> Video
                          Not Playing
                        </video>
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className="listMediaTitle"
                      >
                        <Typography variant="p">{data.Title}</Typography>
                      </TableCell>
                    </TableRow>
                  </Link>
                </TableBody>
              </Table>
            </TableContainer>
          );
        })}
      </div>
    );
  };

  const trimWhiteSpace = (str) => {
    let result = str.replace(/\s/g, "");
    return result;
  };

  const { id } = useParams();

  return (
    <div className="demoContainer">
      <main>
        <Grid container xs={12} sm={12} className="fadePanel">
          <Grid item xs={12} sm={12}>
            <Typography variant="h5" className="pageTitleSection">
              Demo Videos
            </Typography>

            {VideoContent(id)}

            <div className="spacer" />
            <div className="spacer" />
          </Grid>
        </Grid>
      </main>
    </div>
  );
}

export default DemosPage;
